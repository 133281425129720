import videoPlaylists from '../data/videoPlaylists.json';
import React from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

const useReactVideoJSPlayer = false;
let i = 0;

class VideoPlayerReact extends React.Component {
	// Instantiate a Video.js player when the component mounts
	componentDidMount() {
		this.player = videojs(this.videoNode, this.props, () => {
			videojs.log('onPlayerReady', this);
		});
	}

	// Dispose the player when the component will unmount
	componentWillUnmount() {
		if (this.player) {
			// if not localhost dispose
			if (window.location.hostname !== 'localhost') {
				videojs.log('dispose', this);
				this.player.dispose();
			} else {
				videojs.log('dispose requested, ignoring', this);
			}
		}
	}

	// Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
	// won't create additional wrapper in the DOM.
	//
	// See: https://github.com/videojs/video.js/pull/3856
	render() {
		videojs.log('render', this);
		return (
		<div>
			<div data-vjs-player>
			<video 
				ref={(node) => { this.videoNode = node; }}
				className="video-js"
				crossorigin="anonymous"
			></video>
		</div>        
		</div>
		);
	}
}

function VideoPlayerJavaScript( {options} ) {
	return (  
	<video
		id={options.id}
		className="video-js vjs-fluid"
		controls
		preload={options.preload}
		poster={options.poster}
		data-setup='{}'
	>
	<VideoPlayerSources key={i++} sources={options.sources} />

	<p className="vjs-no-js">
		To view this video please enable JavaScript, and consider upgrading to a
		web browser that
		<a href="https://videojs.com/html5-video-support/" rel="noreferrer" target="_blank">supports HTML5 video</a>
	</p>
	</video>
	);
}

function VideoPlayerSources( {sources} ) {
	return (
	  // for each element in source, create a source 
		sources.map(source =>
			<source key={i++} src={source.src} type={source.type} media={ (source.media ? source.media : null) } />
		)
	);
}

function Video( {video} ) {
	const videoJsOptions = {
		id: video.id,
		preload: 'auto',
		poster: 'https://'+ video.host +'/'+ video.id + '/Thumbnails/'+ video.thumb,
		autoplay: false,
		controls: true,
		fluid: true,
		userActions: { hotkeys: true },
		aspectRatio: video.aspectRatio,
		sources: []
	}

	// note there will be an error https://github.com/videojs/http-streaming/issues/83
	
	if ('formats' in video) {

		if (video.formats.hls) { 
			videoJsOptions.sources.push( {
				src: 'https://'+ video.host +'/'+ video.id + '/HLS/'+ video.id + '.m3u8',
				type: 'application/x-mpegURL'
			});
		}

		if (video.formats.mp4_uhd4k) {
			videoJsOptions.sources.push( {
				src: 'https://'+ video.host +'/'+ video.id + '/MP4/'+ video.id + '_UHD4K.mp4',
				type: 'video/mp4',
				media: "(min-width: 3800px)"
			});
		}
		
		if (video.formats.mp4_hd1080) {
			videoJsOptions.sources.push( {
				src: 'https://'+ video.host +'/'+ video.id + '/MP4/'+ video.id + '_HD1080.mp4',
				type: 'video/mp4',
				media: "(min-width: 1900px)"
			});
		}

		if (video.formats.mp4_hd720) {
			videoJsOptions.sources.push( {
				src: 'https://'+ video.host +'/'+ video.id + '/MP4/'+ video.id + '_HD720.mp4',
				type: 'video/mp4',
				media: "(min-width: 1200px)"
			});
		}

		if (video.formats.mp4_sd) {
			videoJsOptions.sources.push( {
				src: 'https://'+ video.host +'/'+ video.id + '/MP4/'+ video.id + '_SD.mp4',
				type: 'video/mp4'
			});
		}
	}

	return (useReactVideoJSPlayer) ? <VideoPlayerReact {...videoJsOptions} /> : <VideoPlayerJavaScript options={videoJsOptions} />
}  

function VideoDescription({description}) {
	if (description) {
		return <div className='videoDescription'>{description}</div>
	}
	return null;
}

function VideoSongArtist({artist}) {
	if (artist) {
		return <div className='videoSongArtist'>{artist}</div>
	}
	return null;
}

function VideoSongAlbum({album}) {
	if (album) {
		return <div className='videoSongAlbum'>{album}</div>
	}
	return null;
}

function Videos() {
	const videoListing = videoPlaylists.map(
		videoPlaylist =>
		{ 
			if ( !videoPlaylist.visible ) return null;

			return (
				<div className="videoPlaylist" key={i++}>
					<h3>{videoPlaylist.title}</h3>
					{ videoPlaylist.description ? <div className="videoPlaylistDescription"><p>{videoPlaylist.description}</p></div> : null }
					<ul>
						{videoPlaylist.playlist.map(video =>
						<li key={video.id}>
							<div className="videoTitle"><h4>{video.name}</h4></div>
							<Item video={video}/>
							<VideoSongArtist artist={video.artist} />
							<VideoSongAlbum album={video.album} />
							<VideoDescription description={video.description} />
						</li>
					)}
					</ul>
				</div> 
			)
		}
	);

	// The videos change frequently, we don't want to index them. The page header and intro is more important
	if (/bot|crawl|index|search|google|yahoo|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)) {
		return null;
	} else {
		return (
			<section id="Videos">
				<h2>Previous Shows</h2>
				{videoListing}
			</section>
		);		
	}

}

function Item ( {video} ) {
	console.log('MyVideo', video);
	if (video.host !== "") {
		return <Video key={i++} video={video} />
	}
}

export default Videos;